import {  HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateRoutes } from "./Routes/PrintylishRoutes";
import "react-slideshow-image/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ToastContainerMsg } from "./Utils";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const PrintylishRouting = [...PrivateRoutes()];
  const PrintylishConfig = () => useRoutes(PrintylishRouting);

  return (
    <>
      <HashRouter basename="/">
        <PrintylishConfig />
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
