import {
  ToastContainer,
  toast,
  // cssTransition,
} from "react-toastify";
import { APIURL } from "../ApiServices/Axios/Axios";
// import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { addWishList, deleteWishList } from "../ApiServices/Apifun";
// Get an item from local storage
export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Error getting ${key} from local storage: ${error}`);
    return null;
  }
};

// Set an item in local storage
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting ${key} in local storage: ${error}`);
  }
};

// Remove an item from local storage
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing ${key} from local storage: ${error}`);
  }
};

//antd custom message function
// export const messageConfiguration = (type, message, duration) => {
//   return {
//     type: type,
//     content: message,
//     style: { marginTop: "80px" },
//     duration: duration,
//   };
// };

// custom react-toastify
export const dispatchtoast = (message, type) => {
  if (type) {
    return toast.error(message);
  }
  return toast.success(message);
};

// dynamic function to catch all errors and show on react toastify
export const showError = (error) => {
  if (typeof error === "object") {
    Object.entries(error).map(([key, value]) => {
      const errmessage = `${key}: ${value}`;
      return dispatchtoast(errmessage, true);
    });
  } else {
    return dispatchtoast(error, true);
  }
};

// patterns for pin,aadhar,pan,email,mobile,gst
export const pincodePattern = /^[1-9][0-9]{5}$/; // Regular expression for valid PIN code format
// export const aadharPattern = /^\d{12}$/; // Regular expression for valid Aadhaar card number format
// export const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/; // Regular expression for PAN number format
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Regular expression for email format
export const mobilePattern = /^[6-9]\d{9}$/; // Regular expression for valid mobile number format
export const gstPattern =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // GST number pattern
export const upiPattern = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
// export const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
// export const pancardValidation = (text) => {
//   let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
//   if (regex.test(text)) {
//     return true;
//   }
//   return false;
// };

// function to define react-toastify
export const ToastContainerMsg = () => {
  return (
    <ToastContainer
      style={{
        fontSize: "12px",
        color: "black",
        textTransform: "capitalize",
      }}
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      rtl={false}
      theme="light"
    />
  );
};

// LIST of all the services provided by B2B

export const createImageUrl = (imageName, type = "product") => {
  return `${APIURL}/uploads/${type}/${imageName}`;
};

// function to enable search in antd select by label
export const filterOption = (input, option) =>
  option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;

export const trimString = (string, limit = 25) => {
  const trim = string
    ? string.length > limit
      ? string.substring(0, limit) + "..."
      : string
    : "---";
  return trim;
};

export const createUrl = (seoUrl) => {
  const spiltedUrl = seoUrl?.split("/");
  const slicedUrl = spiltedUrl?.slice(3, spiltedUrl.length);
  return slicedUrl?.join("/")?.replace(/\s+/g, "-");
};

export const buildSearchQuery = (o) => {
  let _o = Object.fromEntries(Object.entries(o).filter(([_, v]) => v != null));
  const searchString = new URLSearchParams(_o).toString();
  return searchString && searchString.length ? `?${searchString}` : "";
};

export const CreateDynamicArray = (number) => {
  let num = [];
  for (let index = 1; index <= number; index++) {
    num = [...num, index];
  }
  return num;
};

export const CallPitchPrintClient = async (
  setAllPitchPrintImages,
  complateDetail,setShowSkeleton
) => {
  setShowSkeleton(true)
  try {
    const GetToken = await axios.post("https://api.pitchprint.io/client/init", {
      userId: "guest",
      apiKey: process.env.REACT_APP_PITCHPRINT_API_KEY,
      // designId: complateDetail?.category_design_id,
      client: "ps",
      version: "9.1.0",
    });
    if (GetToken.data.token) {
      const CallFetchCatDetail = await axios.post(
        "https://api.pitchprint.io/client/fetch-cat-details",
        {
          id: complateDetail?.category_design_id,
          token: GetToken.data.token,
        }
      );
      setAllPitchPrintImages(CallFetchCatDetail?.data?.data?.designs);
    }
  } catch (error) {
    console.log(error);
  }finally{
    setShowSkeleton(false)
  }
};
export const PitchPrintFun = (
  designs,
  setPageLoader,
  callBack,
  callback2,
  mode = "new"
) => {
  const currentDate = Date.now();
  const { user_first_name, email } =
    getLocalStorageItem("userStore")?.state?.userProfile?.user_profile;
  const config = {
    apiKey: process.env.REACT_APP_PITCHPRINT_API_KEY, //Kinldy provide your own APIKey
    designId: designs, //Change this to your designId,
    projectId: designs,
    custom: false,
    userData: JSON.stringify({
      firstname: user_first_name,
      lastname: `${designs}${currentDate}`,
      email: email,
    }),
    mode: mode,
  };
  mode == "edit" ? delete config["designId"] : delete config["projectId"];
  /*global PitchPrintClient*/
  var ppclient = new PitchPrintClient(config);
  const appValidated = () => {
    ppclient.showApp(); //Attach event listener to the button when clicked to show the app
    setPageLoader(false);
  };
  const projectSaved = (_val) => {
    let _data = _val.data;
    if (_data.previews && !_data?.saveForLater) {
      callBack(_data.previews, `${designs}${currentDate}`);
    } else if (_data.previews && _data?.saveForLater) {
      callback2(_data.projectId, _data.previews);
    } else {
      dispatchtoast("Some Error Occured", true);
    }
  };
  ppclient.on("app-validated", appValidated);
  ppclient.on("project-saved", projectSaved);
};
