import React from "react";
const OrderDetail = React.lazy(() =>
  import("../Main/Pages/MyOrders/OrderDetail")
);
const ShippingPolicy = React.lazy(() =>
  import("../Main/Pages/Others/ShippingPolicy")
);
const RefundPolicy = React.lazy(() =>
  import("../Main/Pages/Others/RefundPolicy")
);
const MyOrders = React.lazy(() => import("../Main/Pages/MyOrders/MyOrders"));
const OrderSuccess = React.lazy(() =>
  import("../Main/Pages/OrderSuccess/OrderSuccess")
);
const Checkout = React.lazy(() => import("../Main/Pages/Checkout/Checkout"));
const Cart = React.lazy(() => import("../Main/Pages/MyCart/Cart"));
const Favourites = React.lazy(() =>
  import("../Main/Pages/Favourite/Favourite")
);

const SavedProjects = React.lazy(() =>
  import("../Main/Pages/Projects/SavedProjects")
);
// const BulkOrder = React.lazy(() => import("../Main/Pages/BulkOrder/BulkOrder"));
const DummyDesign = React.lazy(() =>
  import("../Main/Pages/DummyDesign/DummyDesign")
);
const Home = React.lazy(() => import("../Main/Pages/Home/Home"));
const ProductDesc = React.lazy(() =>
  import("../Main/Pages/ProductDesc/ProductDesc")
);
const ProductShapes = React.lazy(() =>
  import("../Main/Pages/ProductSahpes/ProductShapes")
);
const PrintylishAuth = React.lazy(() => import("./PrintylishAuth"));
const Profile = React.lazy(() => import("../Main/Pages/Profile/index"));
const Tandc = React.lazy(() => import("../Main/Pages/Others/Tandc"));
const PrivacyCookie = React.lazy(() =>
  import("../Main/Pages/Others/PrivacyCookie")
);
const Copyright = React.lazy(() => import("../Main/Pages/Others/Copyright"));
const AboutUs = React.lazy(() => import("../Main/Pages/Others/AboutUs"));
const ProductPage = React.lazy(() => import("../Main/Pages/Home/ProductPage"));
const FeaturedProduct = React.lazy(() =>
  import("../Main/Pages/Home/FeaturedProduct")
);

export const PrivateRoutes = () => {
  return [
    {
      element: <PrintylishAuth />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/saved-projects",
          element: <SavedProjects />,
        },
        {
          path: "/:productChain",
          element: <ProductShapes />,
        },
        {
          path: "/products",
          element: <ProductPage />,
        },
        {
          path: "/featured-products",
          element: <FeaturedProduct />,
        },
        {
          path: "/product/:product-name",
          element: <ProductDesc />,
        },
        {
          path: "/product-designs",
          element: <DummyDesign />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/favourites",
          element: <Favourites />,
        },
        {
          path: "/cart",
          element: <Cart />,
        },
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/order-success",
          element: <OrderSuccess />,
        },
        {
          path: "/orders",
          element: <MyOrders />,
        },
        {
          path: "/orderDetail",
          element: <OrderDetail />,
        },
        {
          path: "/t&c",
          element: <Tandc />,
        },
        {
          path: "/privacy",
          element: <PrivacyCookie />,
        },
        {
          path: "/copyright",
          element: <Copyright />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/refund-policy",
          element: <RefundPolicy />,
        },
        {
          path: "/shipping-policy",
          element: <ShippingPolicy />,
        },
       
      ],
    },
  ];
};
